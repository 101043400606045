import React from 'react';
import Layout from '../layouts/en';
import Careers from '../components/pages/careers';

const CareersPage = props => (
  <Layout {...props}>
    <Careers />
  </Layout>
);

export default CareersPage;
